import React, { useState } from "react";
import MenuLocationIcon from "assets/icons/MenuLocationIcon";
import RegCheckIcon from "assets/icons/RegCheckIcon";
import { Spin } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { layoutLang } from "utils/language/layout";
import { useGetAllRegion } from "../../services/queries";
import { clearObj } from "utils/helpers/clearObj";
import { LOCATION } from "utils/constants/queryParamsKeys";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
const RegionSearch = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    push,
    query
  } = useRouter();
  const [loading, setLoading] = useState(false);
  const location = useAppSelector(state => state.favouritesReducer?.location);
  const locationType = useAppSelector(state => state.favouritesReducer?.locationType);
  const hotOffersRegionValue = useAppSelector(state => state.favouritesReducer?.hotOffersRegionValue);
  const {
    data,
    isLoading
  } = useGetAllRegion(location?.searchValue);
  const {
    setRegionId,
    setDistrictId,
    setOpenRegionDrawer,
    setCurrentRegion
  } = favouritesReducerActions;
  const currentLoc = locationType === "hot_location" ? hotOffersRegionValue : location?.regionValue;
  const handleClose = () => {
    setLoading(false);
    dispatch(setOpenRegionDrawer(false));
  };
  return <div className="flex flex-col gap-3 p-3 rounded-xl bg-white mobileTopFilter" style={{
    height: "calc(100vh - 130px)",
    overflow: "auto"
  }} data-sentry-component="RegionSearch" data-sentry-source-file="RegionSearch.tsx">
      <Spin spinning={isLoading || loading} data-sentry-element="Spin" data-sentry-source-file="RegionSearch.tsx">
        <div className="flex flex-col gap-3">
          <div className={`flex items-center gap-3 px-1 pb-3 border-b border-solid border-[#EAECF0] cursor-pointer`} onClick={() => {
          setLoading(true);
          dispatch(setRegionId(null));
          dispatch(setDistrictId(null));
          locationType !== "mobile_location" && handleClose();
          dispatch(setCurrentRegion({
            type: locationType,
            value: {
              city: null,
              district: null
            }
          }));
          if (locationType === "mobile_location") {
            push({
              query: clearObj({
                ...query,
                city_id: null,
                district_id: null,
                [LOCATION]: DEFAULT_LOCATION
              })
            }).then(handleClose);
          }
        }}>
            <MenuLocationIcon data-sentry-element="MenuLocationIcon" data-sentry-source-file="RegionSearch.tsx" />
            <div className="flex flex-col">
              <span className="text-[#344054] font-medium">
                {layoutLang[locale || 'ru']["Butun_Ozbekiston_boylab"]}
              </span>
            </div>
          </div>
          {data?.map((item, index) => <div key={index}>
              {item?.district ? <div className={`flex items-center gap-3 px-1 pb-3 ${index === data?.length - 1 ? "border-none" : "border-b"} border-solid border-[#EAECF0] cursor-pointer`} onClick={() => {
            setLoading(true);
            locationType !== "mobile_location" && handleClose();
            dispatch(setCurrentRegion({
              type: locationType,
              value: item
            }));
            if (locationType === "mobile_location") {
              push({
                query: clearObj({
                  ...query,
                  [LOCATION]: `${item?.district?.city?.url}-${item?.district?.url}` || DEFAULT_LOCATION
                })
              }).then(handleClose);
            }
          }}>
                  <MenuLocationIcon />
                  <div className="flex flex-col">
                    <span className="text-[#344054] font-medium">
                      {item?.district?.name?.[locale as keyof typeof item.district.name]}
                    </span>
                    <span className="text-[#667085] text-xs">
                      {item?.district?.city?.name[locale as keyof typeof item.district.city.name]}
                    </span>
                  </div>
                  {currentLoc?.district?.id === item?.district?.id && <div className="absolute right-5">
                      <RegCheckIcon />
                    </div>}
                </div> : <div className={`flex items-center gap-3 px-1 pb-3 ${index === data?.length - 1 ? "border-none" : "border-b"} border-solid border-[#EAECF0] cursor-pointer`} onClick={() => {
            setLoading(true);
            item?.city?.id && dispatch(setRegionId(item?.city?.id));
            dispatch(setDistrictId(null));
            locationType !== "mobile_location" && handleClose();
            dispatch(setCurrentRegion({
              type: locationType,
              value: item
            }));
            if (locationType === "mobile_location") {
              push({
                query: clearObj({
                  ...query,
                  [LOCATION]: item?.city?.url || DEFAULT_LOCATION
                })
              }).then(handleClose);
            }
          }}>
                  <MenuLocationIcon />
                  <span>
                    {item?.city?.name[locale as keyof typeof item.city.name]}
                  </span>
                  {currentLoc?.city?.id === item?.city?.id && <div className="absolute right-5">
                      <RegCheckIcon />
                    </div>}
                </div>}
            </div>)}
        </div>
      </Spin>
    </div>;
};
export default RegionSearch;